import * as React from 'react';
import { Box, Card, CardContent, Typography, Chip } from '@material-ui/core';

interface Item {
    item: string;
    shippedQuantity: number;
    totalPrice: number;
    unitPrice: number;
}

interface InvoiceData {
    driverName: string;
    invoiceDate: string;
    invoiceNo: number;
    items: Item[];
}

interface BasicCardProps {
    data: InvoiceData;
}

export default function BasicCard({ data }: BasicCardProps) {
    return (
        <Card style={{ minWidth: 245, textAlign: 'left' }}>
            <CardContent>
                <Typography>
                    Invoice No: <Chip label={data.invoiceNo} color="primary" variant="default" />
                </Typography>
                <Typography>
                    Invoice Date: {new Date(data.invoiceDate).toLocaleDateString()}
                </Typography>
                <Typography>
                    Driver: {data.driverName}
                </Typography>
                {data.items.length > 0 && data.items.map((item, index) => (
                    <Box key={index} mt={1}>
                        <Typography>
                            Item: {item.item}
                        </Typography>
                        <Typography>
                            Shipped Quantity: {item.shippedQuantity}
                        </Typography>
                        <Typography>
                            Unit Price: {item.unitPrice}
                        </Typography>
                        <Typography>
                            Total Price: {item.totalPrice}
                        </Typography>
                        <hr />
                    </Box>
                ))}
            </CardContent>
        </Card>
    );
}
