import React from 'react'
import { Grid, TextField, makeStyles, Paper, TableBody, Select, Box, TableCell, FormControl, Button, TableContainer, Table, Typography, InputLabel, MenuItem } from '@material-ui/core';
import { Container, CssBaseline } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import axios from "axios";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { getToken } from '../Common/helper';
import { BASE_URL } from '../Common/constant';
import AgentSearch from '../Common/AgentSearch';
import { ToastContext } from '../Common/ToastProvider';
import { ConfirmDialogContext } from '../Common/ConfirmDialogProvider';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import HistoryTable from './HistoryTable';
import UpdateTable from './UpdateTable';
import BasicCard from './Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    fieldWidth: {
        minWidth: 180
    },
    root: {
        flexGrow: 1,
        backgroundColor: '#004e8d',
        color: "white"

    },
});

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#004e8e",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);


interface IRecord {
    id: number,
    recordDate: Date
    dname: string
    item: string
    agent: string
    dQnty: number
    rate: number
    totalAmt: number
    paidCash: number
    paidOnline: number
    dueAmt: number
    remarks: string
    fare: number
    totalFare: number
    finalAmount: number

}

const RefilSale = () => {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date()
    );
    const { showToast } = React.useContext(ToastContext);
    const [agentList, setAgetList] = React.useState([]);
    const [history, setHistory] = React.useState([])

    const [requestData, setRequestData] = React.useState({
        invoiceType: "",
        invoiceNo: null,
        startDate: null,
        endDate: null
    });


    const [sale, setSale] = React.useState({
        item: '',
        date: '',
        dName: '',
        agent: '',
        dQnty: 0,
        totalAmount: 0,
        paidCash: 0,
        paidOnline: 0,
        dueAmount: 0,
        remarks: '',
        finalAmount: 0,
        rate: 0

    })

    const [selectedAgent, setSelectedAgent] = React.useState('');
    const [selectedRate, setSelectedRate] = React.useState({});
    const [selectedFare, setSelectedFare] = React.useState(0);
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setInvoices([])

    };

    React.useEffect(() => {
        getAllAgents()
    }, [])


    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setSale(prevSale => {
            let updatedSale = { ...prevSale, [name]: value };

            if (name === 'item') {
                switch (value) {
                    case '14KG':
                        //@ts-ignore
                        updatedSale.rate = selectedRate ? selectedRate.KG14Rate : 0;
                        break;
                    case '19KG':
                        //@ts-ignore
                        updatedSale.rate = selectedRate ? selectedRate.KG19Rate : 0;
                        break;
                    case '5KG':
                        //@ts-ignore
                        updatedSale.rate = selectedRate ? selectedRate.KG5Rate : 0;
                        break;
                    default:
                        updatedSale.rate = 0;
                }
            }
            return updatedSale;
        });
    };


    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    async function getAllAgents() {
        try {
            const result = await axios.get(BASE_URL + "agent/getall/active", {
                headers: {
                    encryption: false,
                    token: getToken()
                },
            });

            if (result.data.data.agents.length !== 0) {
                //@ts-ignore
                const agents = result.data.data.agents.map(({ name, mobile, fare, KG14Rate, KG19Rate, KG5Rate }) => ({
                    label: name,
                    value: name,
                    fare: fare,
                    KG14Rate: KG14Rate,
                    KG19Rate: KG19Rate,
                    KG5Rate: KG5Rate
                }));
                setAgetList(agents);
            }
        }
        catch (error) {
            if (error) {
                //@ts-ignore
                showToast(error.response.data.message, "error");
            }
        }
    }



    const itemTotal = () => {
        return sale.rate * sale.dQnty
    }

    const totalFare = () => {
        return selectedFare * sale.dQnty
    }

    const totalPaid = () => {
        return Number(sale.paidCash) + Number(sale.paidOnline)
    }

    const dueAmount = () => {
        const dueAmt = itemTotal() - totalPaid()
        return dueAmt
    }
    const handleAgentSelect = (agent: any) => {
        setSelectedAgent(agent.value);
        setSelectedRate(agent);
        setSelectedFare(agent.fare)

    };

    const finalAmount = () => {
        return dueAmount() - totalFare()
    }


    const [recordData, setRecordData] = React.useState<any[]>([]);
    const saveRowData = () => {

        let newData = {
            id: recordData.length,
            item: sale.item,
            recordDate: selectedDate,
            dname: sale.dName,
            agent: selectedAgent,
            quantity: sale.dQnty,
            rate: sale.rate,
            totalAmt: itemTotal(),
            paidCash: sale.paidCash,
            paidOnline: sale.paidOnline,
            dueAmt: dueAmount(),
            remarks: sale.remarks,
            fare: selectedFare,
            totalFare: totalFare(),
            finalAmount: finalAmount(),

        };
        if (!sale.dName || !selectedAgent || sale.rate <= 0 || itemTotal() <= 0 || selectedFare < 0 || totalFare() <= 0) {
            showToast("Please fill in all fields correctly", "warn");
            return;
        }
        setRecordData(prevData => [...prevData, newData]);
    };

    const removeRowData = (indexToRemove: any) => {
        setRecordData(prevData => prevData.filter(item => item.id !== indexToRemove));
        showToast("Row removed from the table", "warn");
    };


    const fetchRefillSale = async () => {
        try {
            const result = await axios.get(BASE_URL + "refillsale/history",
                {
                    headers: {
                        encryption: false,
                        token: getToken()
                    },
                })
            if (result.data && result.data.result.data) {
                showToast(`Fetched refill sale history successfully`, "success");
                setHistory(result.data.result.data);
            }

        } catch (error) {
            if (error) {
                //@ts-ignore
                console.log("error", error)
                showToast(`Refill sale saving error`, "error");

            }
        }
    };


    const updateHandle = async () => {
        try {
            showConfirmDialog("Are you sure to save these records", "Do you want to save refill entries ?", async () => {

                const result = await axios.post(
                    BASE_URL + "refillsale/add",
                    recordData,
                    {
                        headers: {
                            encryption: false,
                            token: getToken()
                        },
                    }
                );

                if (result.data) {
                    showToast(result.data.message, "success");
                }
            })
        } catch (error) {
            showToast("An error occurred", "error");
        }
    };

    const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

    const calculateSum = (data: Array<IRecord>) => {
        return data.reduce((accumulator, entry) => {
            accumulator.dQnty += Number(entry.dQnty);
            accumulator.totalSum += Number(entry.totalAmt);
            accumulator.fareSum += Number(entry.totalFare);
            accumulator.cashSum += Number(entry.paidCash);
            accumulator.onlineSum += Number(entry.paidOnline);
            accumulator.dueAdvance += Number(entry.dueAmt);
            accumulator.finalAmtSum += Number(entry.finalAmount);
            return accumulator;
        }, {
            dQnty: 0,
            totalSum: 0,
            fareSum: 0,
            cashSum: 0,
            onlineSum: 0,
            dueAdvance: 0,
            finalAmtSum: 0,
        });
    };


    const columns = [
        {
            title: "Record Date", field: "recordDate", type: "date", dateSetting: { locale: "ko-KR" }
        }, { title: "Driver Name", field: "dname" }, { title: "Driver Load", field: "quantity" },
        { title: "Agent Name", field: "agent" },
        { title: "Refil Qnty", field: "dload" },
        { title: "Rate", field: "rate" },
        { title: "Total Amount", field: "totalAmt" },
        { title: "Due Amount", field: "dueAmt" },
        { title: "Fare", field: "fare" },
        { title: "Total Fare", field: "totalFare" },
        { title: "Paid Cash  Recived", field: "paidCash" },
        { title: "Paid Online", field: "paidOnline" },
        { title: "Due Amount", field: "dueAmt" },
        { title: "Final Amount", field: "finalAmount" },
        { title: "Remarks ", field: "remarks" },
        {
            title: "Entry Date ", field: "createdAt", type: "date",
            dateSetting: { locale: "ko-KR" }
        }

    ]

    const handleInvoiceTypeChange = (event: any) => {
        const { value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            invoiceType: value
        }));
    };
    const handleInvoiceNoChange = (event: any) => {
        const { value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            invoiceNo: value
        }));
    };
    const [invoices, setInvoices] = React.useState([]);


    const handleFindInvoice = async () => {
        const { invoiceType, invoiceNo, startDate, endDate } = requestData;
        let payload = {};
        try {
            if (invoiceNo) {
                payload = { findkey: "invoiceNo", invoiceNo: invoiceNo };
            } else if (invoiceType) {
                payload = { findkey: "status", status: invoiceType };
                // } else if (requestType === "dateRange") {
                //     payload = { findkey: "dateRange", startDateISO: startDate, endDateISO: endDate };
            }
            const result = await axios.post(
                BASE_URL + "invoice/get",
                payload,
                {
                    headers: {
                        encryption: false,
                        token: getToken()
                    },
                }
            )
            if (result.data && result.data.result.data) {
                showToast(`Fetched invoice history successfully`, "success");
                setInvoices(result.data.result.data)
                // setRecordData(result.data.result.data)
            }
        } catch (error) {
            showToast("No record found", "error");
        }
    };




    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" style={{ marginTop: "5rem" }}>
                <Typography variant='h6'>
                    Refill Sale Management
                </Typography>

                <Box >
                    <Paper className={classes.root}>
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            //@ts-ignore
                            indicatorColor="secondary"
                            textColor="inherit"
                            centered>
                            <Tab label="Accept & Update Invoice" />
                            <Tab label="Refill History" />
                        </Tabs>
                    </Paper>
                </Box>
            </Container>

            {value === 0 &&
                <Container maxWidth="md" style={{ backgroundColor: "white", padding: "3rem", marginTop: "2rem" }}>
                    <Typography>
                        View/Accept Invoice
                    </Typography>
                    <Grid container spacing={0} >
                        <Grid item xs={12} md={4} sm={12}>
                            <TextField id="outlined-basic"
                                label="Invoice No"
                                variant="standard"
                                size='medium'
                                value={requestData.invoiceNo}
                                onChange={handleInvoiceNoChange}
                                name='invoiceNo'
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={12}>
                            <FormControl variant="standard" className={classes.fieldWidth}>
                                <InputLabel id="demo-simple-select-label">Invoice Type</InputLabel>
                                <Select
                                    variant='standard'
                                    id="demo-simple-select-filled"
                                    value={requestData.invoiceType}
                                    onChange={handleInvoiceTypeChange}
                                    name="invoiceType"
                                >
                                    <MenuItem value="release">Release</MenuItem>
                                    <MenuItem value="accept">Accept</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="dense"
                                    id="date-picker-dialog"
                                    label="Invoice Date"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}

                                />

                            </MuiPickersUtilsProvider>
                        </Grid>

                    </Grid>

                    <Grid container>
                        <Grid style={{ margin: "auto", justifyContent: "center", textAlign: "center", marginTop: "2rem" }}>
                            <Button size="medium" variant='contained' color='secondary' onClick={handleFindInvoice} >View Invoice</Button>
                        </Grid>
                    </Grid>

                </Container>
            }
            {value === 1 &&
                <Container maxWidth="xs" style={{ textAlign: "center", backgroundColor: "white", padding: "3rem", marginTop: "2rem" }} >

                    <Grid container >
                        <Grid item xs={12} sm={12} md={12}>
                            <Button variant='contained' color='secondary' onClick={fetchRefillSale}>Refill Sale History<DownloadForOfflineIcon /></Button>

                        </Grid>
                    </Grid>
                </Container>}

            {requestData.invoiceType === "release" ?
                <div>
                    {invoices.length > 0 &&
                        <UpdateTable records={invoices} />
                    }
                </div> :

                <Container style={{ backgroundColor: "white", marginTop: "1rem", paddingTop: "5px" }}>


                    {invoices.length > 0 &&
                        <>
                            <Typography variant="body1">
                                Accepted Invoices:
                            </Typography>
                            <br></br>

                            <Grid container spacing={2}>
                                {invoices
                                    .filter((invoice: any) => invoice.status === "accept")
                                    .map((invoice: any, index: number) => (
                                        <Grid item xs={6} sm={6} md={3} key={invoice.invoiceNo}>
                                            <BasicCard data={invoice} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </>
                    }
                </Container>
            }



            {requestData.invoiceType === "accept" && invoices.length > 0 &&
                <Container style={{ marginTop: "5rem", padding: "2rem", backgroundColor: "white" }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Refill Update
                    </Typography>

                    <Grid container style={{ marginTop: "2rem" }} >
                        <Grid item xs={12} md={2} sm={12} style={{ padding: "2px", marginTop: "-16px" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Choose Date"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />

                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField id="outlined-basic"
                                label="Driver Name"
                                variant="standard"
                                value={sale.dName}
                                onChange={handleChange}
                                name='dName'
                            />
                        </Grid>

                        <Grid item xs={12} md={2} sm={12}>
                            <AgentSearch onSelectAgent={handleAgentSelect} list={agentList} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} >
                            <FormControl variant="standard" >
                                <InputLabel id="demo-simple-select-label">Item</InputLabel>
                                <Select
                                    style={{ width: "10rem" }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="item"
                                    value={sale.item}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="14KG">14KG</MenuItem>
                                    <MenuItem value="19KG">19KG </MenuItem>
                                    <MenuItem value="5KG">5KG </MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>


                            <TextField id="outlined-basic"
                                value={sale.dQnty}
                                onChange={handleChange}
                                label="Refill Qnty " variant="standard"
                                name='dQnty'
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField type='number'
                                value={sale.rate}
                                name='rate'
                                onChange={handleChange}
                                id="outlined-basic" label="Rate" variant="standard" />
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12} md={2} sm={12}>

                            <TextField type='number'
                                id="outlined-basic"
                                value={itemTotal()}
                                name='totalAmount'
                                label="Total Amount" variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField type='number'
                                id="outlined-basic"
                                value={sale.paidCash}
                                name='paidCash'
                                onChange={handleChange}
                                label="Paid Cash" variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField type='number'
                                id="outlined-basic"
                                value={sale.paidOnline}
                                name='paidOnline'
                                onChange={handleChange}
                                label="Paid online" variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>

                            <TextField type='number'
                                id="outlined-basic"
                                value={dueAmount()}
                                name='dueAmount'
                                label="Due/Advance Amount" variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>

                            <TextField id="outlined-basic"
                                value={sale.remarks}
                                name='remarks'
                                onChange={handleChange}
                                label="Remarks" variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>

                            <TextField type='number'
                                id="outlined-basic"
                                value={selectedFare}
                                name='fare'
                                onChange={handleChange}
                                label="Fare" variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>

                            <TextField type='number'
                                id="outlined-basic"
                                value={totalFare()}
                                name='Total fare'
                                label="Total Fare"
                                variant="standard" />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12} >
                            <h2>Total:{finalAmount()}</h2>
                        </Grid>
                        <Grid />

                    </Grid>
                    <Button variant='contained' color='primary' onClick={saveRowData}>add row<AddIcon /></Button>
                </Container>
            }
            <Container component="main" maxWidth="lg" style={{ marginTop: "4rem" }} >
                <Grid >
                    <Grid item xs={12} >
                        {/* <Grid container>
                            <Grid item>
                                <Card>
                                    <CardContent>
                                        <Typography variant="body2">
                                            {sale.dQnty}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div className="container">
                            {recordData.length > 0 &&
                                <div>


                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Date</StyledTableCell>
                                                    <StyledTableCell>Driver Name</StyledTableCell>
                                                    <StyledTableCell >Item</StyledTableCell>
                                                    <StyledTableCell >Agent Name</StyledTableCell>
                                                    <StyledTableCell >Driver Qnty</StyledTableCell>
                                                    <StyledTableCell >Rate</StyledTableCell>
                                                    <StyledTableCell >Total Amount</StyledTableCell>
                                                    <StyledTableCell >Paid Cash</StyledTableCell>
                                                    <StyledTableCell >Paid Online</StyledTableCell>
                                                    <StyledTableCell >Due Amount</StyledTableCell>
                                                    <StyledTableCell >Remarks</StyledTableCell>
                                                    <StyledTableCell >Fare</StyledTableCell>
                                                    <StyledTableCell >Total Fare</StyledTableCell>
                                                    <StyledTableCell >Final Amount</StyledTableCell>
                                                    <StyledTableCell >Remove Row</StyledTableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {recordData.map((record: IRecord) =>

                                                    <StyledTableRow key={record.agent}>

                                                        <StyledTableCell >
                                                            {record.recordDate?.toLocaleDateString()}</StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">

                                                            {record.dname}
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            {record?.item}</StyledTableCell>

                                                        <StyledTableCell >
                                                            {record?.agent}</StyledTableCell>
                                                        <StyledTableCell >
                                                            {record?.dQnty}</StyledTableCell>
                                                        <StyledTableCell >{record.rate}</StyledTableCell>
                                                        <StyledTableCell >{record.totalAmt}</StyledTableCell>
                                                        <StyledTableCell >{record.paidCash}</StyledTableCell>
                                                        <StyledTableCell >{record.paidOnline}</StyledTableCell>
                                                        <StyledTableCell >{record.dueAmt}</StyledTableCell>
                                                        <StyledTableCell >{record.remarks}</StyledTableCell>
                                                        <StyledTableCell >{record.fare}</StyledTableCell>
                                                        <StyledTableCell >{record.totalFare}</StyledTableCell>
                                                        <StyledTableCell >{record.finalAmount}</StyledTableCell>
                                                        <StyledTableCell >

                                                            <IconButton aria-label="delete" size="small" >
                                                                <DeleteIcon fontSize="small" color='secondary' onClick={() => removeRowData(record.id)} />
                                                            </IconButton>
                                                        </StyledTableCell>


                                                    </StyledTableRow>
                                                )}
                                                <StyledTableRow style={{ backgroundColor: "#004e8d", color: "white" }}>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} >Total</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} >-</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}>-</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}>-</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}>{calculateSum(recordData).dQnty}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} >-</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}>{calculateSum(recordData).totalSum}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} >{calculateSum(recordData).cashSum}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}>{calculateSum(recordData).onlineSum}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} >{calculateSum(recordData).dueAdvance}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} ></StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}></StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }} >{calculateSum(recordData).fareSum}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}>{calculateSum(recordData).finalAmtSum}</StyledTableCell>
                                                    <StyledTableCell style={{ color: "white", fontWeight: "bold" }}></StyledTableCell>

                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                    <Button variant='contained' color='secondary' onClick={updateHandle}>Save refill table</Button>
                                </div>
                            }
                        </div>


                    </Grid>

                </Grid>

            </Container>

            <Container>
                {history.length > 0 &&
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <HistoryTable title="JAMAN HP REFILL SALES" columns={columns} data={history} />
                        </Grid>
                    </Grid>}
            </Container>

        </React.Fragment>
    )
}

export default RefilSale









