import React, { useState, useEffect } from 'react';
import { Container, Grid, TableContainer, Typography, Table, Chip, TableHead, TableRow, TableCell, TableBody, TextField, Button, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import axios from 'axios';
import { BASE_URL } from '../Common/constant';
import { ToastContext } from '../Common/ToastProvider';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ConfirmDialogContext } from '../Common/ConfirmDialogProvider';
import CancelIcon from '@mui/icons-material/Cancel';
import { getRole, getToken } from '../Common/helper';

interface IRecord {
    _id: string;
    driverName: string;
    invoiceNo: string;
    invoiceDate: string;
    items: Array<{
        item: string;
        shippedQuantity: number;
        unitPrice: number;
        totalPrice: number;
    }>;
    remarks?: string;
    status: string;
}

const UpdateTable = ({ records }: { records: IRecord[] }) => {
    const [editingRowId, setEditingRowId] = useState<string | null>(null);
    const [originalValues, setOriginalValues] = useState<{ [key: string]: IRecord }>({});
    const [data, setData] = useState<IRecord[]>([]);
    const [currentRecord, setCurrentRecord] = useState<Partial<IRecord>>({});
    const [updatedRecord, setUpdatedRecord] = useState<Partial<IRecord>>({});
    const { showToast } = React.useContext(ToastContext);
    const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

    useEffect(() => {
        setData(records);
    }, [records]);

    const handleEdit = (id: string) => {
        const record = data.find(r => r._id === id);
        if (record) {
            setOriginalValues(prevOriginalValues => ({
                ...prevOriginalValues,
                [id]: record
            }));
            setCurrentRecord(record);
            setEditingRowId(id);
        }
    };

    const cancelEdit = () => {
        if (editingRowId) {
            setData(prevData => prevData.map(record => record._id === editingRowId ? originalValues[editingRowId] : record));
            setEditingRowId(null);
            setCurrentRecord({});
        }
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const parsedValue = isNaN(parseFloat(value)) ? value : parseFloat(value);
        setCurrentRecord((prevState: any) => {
            const updatedRecord = {
                ...prevState,
                [name]: parsedValue,
            };
            if (name === 'shippedQuantity' || name === 'unitPrice') {
                const shippedQuantity = name === 'shippedQuantity' ? parsedValue : prevState.shippedQuantity || 0;
                const unitPrice = name === 'unitPrice' ? parsedValue : prevState.unitPrice || 0;
                updatedRecord.totalPrice = shippedQuantity * unitPrice;
            }
            return updatedRecord;
        });
    };

    const resetRecord = () => {
        setUpdatedRecord({});
    };

    const sanitizeRecord = (record: any): any => {
        const { createdAt, updatedAt, __v, tableData, ...sanitizedRecord } = record;
        sanitizedRecord.status = getRole() === "admin" ? "release" : "accept";
        return sanitizedRecord;
    };

    const saveInvoice = async () => {
        try {
            if (!updatedRecord._id) {
                return showToast("Select a record to update", "warn");
            }

            showConfirmDialog("Are you sure to save this record", "Do you want to save this entry?", async () => {
                const sanitizedRecord = sanitizeRecord(updatedRecord);
                const response = await axios.patch(BASE_URL + `invoice/${sanitizedRecord._id}`, {}, {
                    headers: {
                        encryption: false,
                        token: getToken()
                    },
                });

                if (response.data && response.data.data) {
                    setData([]);
                    setUpdatedRecord({})
                    showToast(response.data.message, "success");
                }
            });
        } catch (error) {
            showToast("Something went wrong", "error");
            console.error('Error:', error);
        } finally {
            setEditingRowId(null);
            setUpdatedRecord({});
        }
    };

    const handleButtonClick = (recordId: string) => {
        const record = data.find(r => r._id === recordId);
        if (record) {
            setUpdatedRecord(record);
        }
    };

    return (
        <Container maxWidth="lg" style={{ padding: "2rem", margin: "1rem", backgroundColor: "white" }}>
            {data.length > 0 && (
                <Grid container>
                    <Typography>Please select to accept :</Typography>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Driver Name</TableCell>
                                        <TableCell>Invoice No</TableCell>
                                        <TableCell>Invoice Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((record) => (
                                        <TableRow key={record._id}>
                                            {editingRowId === record._id ? (
                                                <>
                                                    <TableCell>
                                                        {currentRecord.driverName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {currentRecord.invoiceNo}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Date(currentRecord.invoiceDate || '').toLocaleDateString()}
                                                    </TableCell>
                                                    {currentRecord.items && currentRecord.items.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {`item: ${item.item}`}
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name='shippedQuantity'
                                                                    value={item.shippedQuantity}
                                                                    onChange={handleChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name='unitPrice'
                                                                    value={item.unitPrice}
                                                                    onChange={handleChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.unitPrice * item.shippedQuantity}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableCell>
                                                        <IconButton aria-label="save" size="small" onClick={saveInvoice}>
                                                            <CheckCircleIcon fontSize="medium" color='secondary' />
                                                        </IconButton>
                                                        <IconButton aria-label="cancel" size="small" onClick={cancelEdit}>
                                                            <CancelIcon fontSize="medium" color='secondary' />
                                                        </IconButton>
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell>{record.driverName}</TableCell>
                                                    <TableCell>{record.invoiceNo}</TableCell>
                                                    <TableCell>{new Date(record.invoiceDate).toLocaleDateString()}</TableCell>
                                                    <TableCell>
                                                        <Chip label={record.status.toUpperCase()} variant="default" color='primary' />
                                                    </TableCell>
                                                    {getRole() === "admin" && (
                                                        <TableCell>
                                                            <Button variant='outlined' size='small' color='secondary' onClick={() => handleButtonClick(record._id)}>
                                                                View
                                                            </Button>
                                                        </TableCell>
                                                    )}
                                                    {getRole() === "superadmin" && (
                                                        <TableCell>
                                                            <IconButton aria-label="edit" size="small" onClick={() => handleEdit(record._id)}>
                                                                <Edit fontSize="small" color='secondary' />
                                                            </IconButton>
                                                        </TableCell>
                                                    )}
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {updatedRecord.invoiceNo && (
                            <>
                                <br />
                                <h3>
                                    Updated Record:
                                    <IconButton aria-label="reset" size="small" onClick={resetRecord}>
                                        Click here to reset
                                        <RotateLeftIcon fontSize="medium" color='secondary' />
                                    </IconButton>
                                </h3>
                                <TableContainer>
                                    <Table aria-label="updated table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Driver Name</TableCell>
                                                <TableCell>Invoice No</TableCell>
                                                <TableCell>Invoice Date</TableCell>
                                                <TableCell>Item</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Unit Price</TableCell>
                                                <TableCell>Total Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {updatedRecord.items && updatedRecord.items.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{updatedRecord.driverName}</TableCell>
                                                    <TableCell>{updatedRecord.invoiceNo}</TableCell>
                                                    <TableCell>{new Date(updatedRecord.invoiceDate || '').toLocaleDateString()}</TableCell>
                                                    <TableCell> <Chip label={item.item} color='secondary' variant='outlined' />
                                                    </TableCell>
                                                    <TableCell>{item.shippedQuantity}</TableCell>
                                                    <TableCell>{item.unitPrice}</TableCell>
                                                    <TableCell>{item.totalPrice}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br />
                            </>

                        )}
                    </Grid>
                    {updatedRecord.invoiceNo && (
                        <Button variant='contained' color='secondary' onClick={saveInvoice} disabled={!updatedRecord.invoiceNo}>
                            {getRole() === "admin" ? "Update" : "Accept Invoice Details"}
                        </Button>
                    )}
                </Grid>
            )}
        </Container>
    );
};

export default UpdateTable;
