import React from 'react'
import { Container, CssBaseline, FormControl, Select } from '@material-ui/core'
import { Grid, TextField, makeStyles, Paper, TableBody, TableCell, Button, TableContainer, Table, Typography, InputLabel, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { getToken } from '../Common/helper';
import { BASE_URL } from '../Common/constant';
import { ToastContext } from '../Common/ToastProvider';
import { ConfirmDialogContext } from '../Common/ConfirmDialogProvider';
import HistoryTable from '../Components/HistoryTable';
import axios from "axios";
import { Create, Timeline, SearchOutlined, Add } from '@material-ui/icons';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { customAlphabet } from 'nanoid'

// Define types for SaleItem and SaleRecord
interface SaleItem {
    item: string;
    shippedQuantity: string;
    unitPrice: string;
    totalPrice: number;
    id: number; // Include id property for each item
}

interface SaleRecord {
    id: number;
    driverName: string;
    invoiceDate: string;
    items: SaleItem[];
    status: string;
    invoiceNo: string;
}


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    fieldWidth: {
        minWidth: 180
    },
    container: {
        marginTop: 50
    },
    root: {
        flexGrow: 1,
        backgroundColor: '#004e8d',
        color: "white"

    },
});



const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#004e8e",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);


interface IRecord {
    _id: string,
    id?: string,
    driverName: string
    invoiceNo: number
    invoiceDate: Date
    item: string
    shippedQuantity: number
    unitPrice: number
    totalPrice: number
}

const InvoiceManagement = () => {
    const classes = useStyles();
    const numbers = '012345678';

    const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
    const nanoid = customAlphabet(numbers, 10);
    const nanoid1 = customAlphabet(numbers, 4)
    const { showToast } = React.useContext(ToastContext);
    const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
    const [recordData, setRecordData] = React.useState<any>({});
    const [invoices, setInvoices] = React.useState([]);
    const [showsave, setShowSave] = React.useState(false);
    const [currentSaleItems, setCurrentSaleItems] = React.useState([]);
    const [value, setValue] = React.useState(0);


    const [sale, setSale] = React.useState({
        id: 0,
        driverName: '',
        invoiceDate: null,
        item: '',
        shippedQuantity: 0,
        unitPrice: 0,
        totalPrice: 0,
        status: "release"
    })

    const handleChange = (event: any) => {

        setSale({ ...sale, [event.target.name]: event.target.value });
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
    };

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setInvoices([])
        setRecordData([])
    };

    const itemTotal = () => {
        return Number(sale.shippedQuantity) * sale.unitPrice
    }



    const addItemToSale = () => {
        if (!sale.item || !sale.shippedQuantity || !sale.unitPrice) {
            showToast("Please fill in all item fields correctly", "warn");
            return;
        }


        //@ts-ignore
        const itemExists = currentSaleItems?.some(item => { return item.item === sale.item; });

        if (itemExists) {
            showToast("This item has already been added", "warn");
            return;
        }
        const newItem = {
            id: nanoid1(),
            item: sale.item,
            shippedQuantity: sale.shippedQuantity,
            unitPrice: sale.unitPrice,
            totalPrice: itemTotal()
        };
        //@ts-ignore
        setCurrentSaleItems(prevItems => {
            const updatedItems = [...prevItems, newItem];
            return updatedItems;
        });
    };


    const finalizeSaleFunction = () => {
        if (!sale.driverName || !selectedDate || currentSaleItems.length === 0) {
            showToast("Please fill in all fields correctly", "warn");
            return;
        }

        if (currentSaleItems.length > 0) {
            const newSaleRecord = {
                driverName: sale.driverName,
                invoiceDate: selectedDate,
                items: currentSaleItems,
                status: "release",
                invoiceNo: nanoid()
            };
            //@ts-ignore
            setRecordData(newSaleRecord);
            setShowSave(true)

        }
        showToast("This table saved locally", "success");

    };




    const removeRowData = (idToRemove: any) => {
        //@ts-ignore
        setCurrentSaleItems(prevData => prevData.filter(item => { return item.id !== idToRemove }));
        showToast("Row removed from the table", "warn");
    };

    const [requestData, setRequestData] = React.useState({
        requestType: "invoice",
        invoiceType: "release",
        invoiceNo: 0,
        startDate: null,
        endDate: null
    });

    const handleRequestTypeChange = (event: any) => {
        const { value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            requestType: value,
            invoiceType: value === "status" ? "release" : "open",
            startDate: null,
            endDate: null
        }));
    };

    const handleInvoiceTypeChange = (event: any) => {
        const { value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            invoiceType: value
        }));
    };

    const handleInvoiceNoChange = (event: any) => {
        const { value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            invoiceNo: value
        }));
    };

    const handleStartDateChange = (date: any) => {
        setRequestData(prevState => ({
            ...prevState,
            startDate: date
        }));
    };

    const handleEndDateChange = (date: any) => {
        setRequestData(prevState => ({
            ...prevState,
            endDate: date
        }));
    };

    const handleInvoice = async () => {
        try {


            showConfirmDialog("Are you sure to save these records", "Do you want to save refill entries ?", async () => {
                const result = await axios.post(
                    BASE_URL + "invoice/add",
                    recordData, {
                    headers: {
                        encryption: false,
                        token: getToken()
                    },
                })

                if (result.data) {
                    showToast(result.data.message, "success");
                    //@ts-ignore
                    setCurrentSaleItems([])
                    //@ts-ignore
                    setRecordData({})
                }
            })
        } catch (error) {
            showToast("An error occurred", "error");
        }
    };


    // const calculateSum = (data: Array<any>) => {
    //     return data.reduce((accumulator, entry) => {
    //         accumulator.totalPrice += Number(entry.totalPrice);
    //         return accumulator;
    //     }, {
    //         totalPrice: 0
    //     });
    // };


    const handleFindInvoice = async () => {
        const { requestType, invoiceType, invoiceNo, startDate, endDate } = requestData;
        let payload = {};
        try {
            if (requestType === "invoice") {
                payload = { findkey: "invoiceNo", invoiceNo: invoiceNo };
            } else if (requestType === "status") {
                payload = { findkey: "status", status: invoiceType };
            } else if (requestType === "dateRange") {
                payload = { findkey: "dateRange", startDateISO: startDate, endDateISO: endDate };
            }
            const result = await axios.post(
                BASE_URL + "invoice/get",
                payload,
                {
                    headers: {
                        encryption: false,
                        token: getToken()
                    },
                }
            )
            if (result.data && result.data.result.data) {
                showToast(`Fetched invoice history successfully`, "success");
                setInvoices(result.data.result.data)
                // setRecordData(result.data.result.data)
            }
        } catch (error) {
            showToast("No record found", "error");
        }
    };


    const columns = [{ title: "Invoice Date", field: "invoiceDate", type: "date", dateSetting: { locale: "ko-KR" } }, { title: "Invoice No", field: "invoiceNo" }, { title: "Driver Name", field: "driverName" },
    { title: "Status", field: "status" },
    { title: "Entry Date ", field: "createdAt", type: "date", dateSetting: { locale: "ko-KR" } }]



    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg" style={{ marginTop: "5rem" }}>
                <Typography variant='h6'>
                    Invoice Management
                </Typography>
                <br>
                </br>
                <Paper >
                    <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        //@ts-ignore
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                        centered>
                        <Tab label="Create Invoice" icon={<Create color='primary' />} />

                        <Tab label="Invoice History" icon={<Timeline color='primary' />} />
                    </Tabs>
                </Paper>
            </Container>

            {
                value === 0 &&

                <Container style={{ marginTop: "5rem", backgroundColor: "white", padding: "2rem" }} maxWidth="lg">
                    <h2>Create invoice </h2>
                    <Grid container>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField
                                id="outlined-basic"
                                label="Driver Name"
                                variant="standard"
                                value={sale.driverName}
                                onChange={handleChange}
                                name='driverName'
                            />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12} style={{ marginTop: "-13px" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Invoice Date"
                                    format="MM/dd/yyyy"
                                    value={selectedDate}
                                    //@ts-ignore
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <FormControl variant="standard" className={classes.fieldWidth}>
                                <InputLabel id="demo-simple-select-label">Item Description</InputLabel>
                                <Select
                                    id="demo-simple-select-filled"
                                    value={sale.item}
                                    onChange={handleChange}
                                    name="item"
                                >
                                    <MenuItem value="14.2_KG">14.2 KG FILLED LPG</MenuItem>
                                    <MenuItem value="19kg">19 KG FILLED LPG</MenuItem>
                                    <MenuItem value="5kg">5KG BLUE(NON DOM)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField
                                type='number'
                                value={sale.shippedQuantity}
                                name='shippedQuantity'
                                onChange={handleChange}
                                id="outlined-basic"
                                label="Shipped Qnty"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} md={2} sm={12}>
                            <TextField
                                type='number'
                                id="outlined-basic"
                                value={sale.unitPrice}
                                name='unitPrice'
                                onChange={handleChange}
                                label="Unit Price"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} md={1} sm={12}>
                            <TextField
                                type='number'
                                id="outlined-basic"
                                value={itemTotal()}
                                label="Total Amount"
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                    <Button variant='contained' color='secondary'
                        onClick={addItemToSale}
                    >
                        Add Item <Add />
                    </Button>


                </Container>

            }


            {
                value === 1 &&
                <Container maxWidth="md" style={{ backgroundColor: "white", padding: "2rem", marginTop: "1rem" }}>
                    <Grid container>
                        <Grid item xs={12} md={3} sm={12}>
                            <FormControl variant="outlined" className={classes.fieldWidth}>
                                <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
                                <Select
                                    id="demo-simple-select-filled"
                                    value={requestData.requestType}
                                    onChange={handleRequestTypeChange}
                                    name="requestType"
                                >
                                    <MenuItem value="invoice">Invoice No</MenuItem>
                                    <MenuItem value="status">Invoice Status</MenuItem>
                                    {/* <MenuItem value="dateRange">Date Range</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        {requestData.requestType === "invoice" && (
                            <Grid item xs={12} md={3} sm={12}>
                                <TextField id="outlined-basic"
                                    label="Invoice No"
                                    variant="standard"
                                    value={requestData.invoiceNo}
                                    onChange={handleInvoiceNoChange}
                                    name='invoiceNo'
                                />
                            </Grid>
                        )}
                        {requestData.requestType === "status" && (
                            <Grid item xs={12} md={2} sm={12}>
                                <FormControl variant="standard" className={classes.fieldWidth}>
                                    <InputLabel id="demo-simple-select-label">Invoice Type</InputLabel>
                                    <Select
                                        id="demo-simple-select-filled"
                                        value={requestData.invoiceType}
                                        onChange={handleInvoiceTypeChange}
                                        name="invoiceType"
                                    >
                                        <MenuItem value="release">Release</MenuItem>
                                        <MenuItem value="accept">Accept</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        {requestData.requestType === "dateRange" && (
                            <>
                                <Grid item xs={12} md={6} sm={12} >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Start date"
                                            value={requestData.startDate}
                                            maxDate={new Date()}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                        <KeyboardDatePicker
                                            margin="normal"
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-dialog"
                                            label="End date"
                                            value={requestData.endDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </>
                        )}
                        <Button variant='contained' color='secondary' onClick={handleFindInvoice}> <SearchOutlined />Invoice History</Button>

                    </Grid>
                    <br />

                </Container>
            }
            <Container>
                <Grid >
                    <Grid item xs={12} >
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <div className={classes.container}>
                            {currentSaleItems.length > 0 &&
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>

                                                    <StyledTableCell>Item</StyledTableCell>
                                                    <StyledTableCell>Quantity</StyledTableCell>
                                                    <StyledTableCell>Unit Price</StyledTableCell>
                                                    <StyledTableCell>Total Price</StyledTableCell>
                                                    <StyledTableCell>Delete Entry</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentSaleItems.map((record: IRecord) => (
                                                    <StyledTableRow key={record.id}>
                                                        <StyledTableCell>{record.item}</StyledTableCell>
                                                        <StyledTableCell>{record.shippedQuantity}</StyledTableCell>
                                                        <StyledTableCell>{record.unitPrice}</StyledTableCell>
                                                        <StyledTableCell>{record.totalPrice}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <IconButton aria-label="delete" size="small">
                                                                <DeleteIcon
                                                                    fontSize="small"
                                                                    color="secondary"
                                                                    onClick={() => removeRowData(record.id)}
                                                                />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}

                                                {recordData.invoiceNo &&
                                                    <StyledTableRow  >

                                                        <StyledTableCell color='primary' >
                                                            Date : {new Date(recordData.invoiceDate).toLocaleDateString()}
                                                        </StyledTableCell>
                                                        <StyledTableCell  >
                                                            Driver : {recordData.driverName}
                                                        </StyledTableCell>

                                                        <StyledTableCell  >
                                                            Invoice No : {recordData.invoiceNo}
                                                        </StyledTableCell>
                                                        <StyledTableCell  >
                                                            {/* ts-ignore */}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                }


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br></br>
                                    <br></br>
                                    <Button variant='contained' color='primary' onClick={finalizeSaleFunction}>Save locally </Button>

                                    {showsave &&
                                        <Button variant='contained' color='secondary' onClick={handleInvoice}>Save table &nbsp; <SaveIcon /></Button>}
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12} sm={12} md={12}>
                        <br></br>
                        {invoices.length > 0 &&
                            <HistoryTable title='Invoice Jaman HP Gas' data={invoices} columns={columns} />}
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment >
    )
}

export default InvoiceManagement









