import React from "react";
import ConnectionDashboard from "../Components/ConnectionDashboard";

const ConnectionPage: React.FC = () => {
    return (
        <section>
            <ConnectionDashboard />
        </section>
    );
};

export default ConnectionPage;
