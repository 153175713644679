import React from 'react'
import MaterialTable from 'material-table';

interface IHistoryTableProp {
    title: string,
    columns: Array<{}>,
    data: Array<{}>
}

function HistoryTable(props: IHistoryTableProp) {




    return (
        <div>
            <MaterialTable
                title={props.title}
                data={props.data}
                //@ts-ignore
                columns={props.columns}
                options={{
                    exportButton: true,
                    exportAllData: true,
                    filtering: true,
                    sorting: true,
                    pageSizeOptions: [5, 20, 50, 100, 200, 500],
                    headerStyle: {
                        backgroundColor: '#004e8d',
                        color: '#FFF'
                    }
                }}
            />
        </div>
    )
}

export default HistoryTable