import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, TextField, Container, Button, FormControlLabel, Switch, Typography,
  Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { BASE_URL } from '../Common/constant';
import { getToken } from '../Common/helper';
import { ToastContext } from '../Common/ToastProvider';

interface Agent {
  name: string;
  mobile: string;
  address: string;
  active: boolean;
  KG14Rate: number;
  KG19Rate: number;
  KG5Rate: number;
  fare: number;
}

interface UpdateAgentData {
  mobile: string;
  KG14Rate?: number;
  KG19Rate?: number;
  KG5Rate?: number;
  fare?: number;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100vh",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  table: {
    minWidth: 700,
  }
}));

const AgentList = () => {
  const classes = useStyles();
  const { showToast } = React.useContext(ToastContext);

  const [list, setList] = useState<Agent[]>([]);
  const [updatedAgents, setUpdatedAgents] = useState<{ [key: string]: { KG14Rate?: number; KG19Rate?: number; KG5Rate?: number; fare?: number } }>({});

  useEffect(() => {
    handleAgentlist();
  }, []);

  const handleAgentlist = async () => {
    try {
      const result = await axios.get<{ status: string; data: { agents: Agent[] } }>(BASE_URL + "agent/getall", {
        headers: {
          encryption: false,
          token: getToken()
        },
      });

      if (result.data && result.data.status === "success") {
        setList(result.data.data.agents);
      }
    } catch (error) {
      //@ts-ignore
      showToast(error.response.data.message, "error");
    }
  };

  const toggleChecked = async (mobile: string) => {
    try {
      const result = await axios.post(BASE_URL + "agent/block", { "mobile": mobile }, {
        headers: {
          encryption: false,
          token: getToken()
        }
      });

      if (result.data && result.data != null) {
        showToast(result.data.message, "success");
        window.location.reload();
      }
    } catch (error) {
      //@ts-ignore
      showToast(error.response.data.message, "error");
    }
  };

  const handleChange = (event: any, agent: Agent) => {
    const { name, value } = event.target;
    setUpdatedAgents(prevState => ({
      ...prevState,
      [agent.mobile]: {
        ...prevState[agent.mobile],
        [name]: parseFloat(value)
      }
    }));
  };

  const handleSubmit = async (agent: Agent) => {
    try {
      const data: UpdateAgentData = {
        mobile: agent.mobile,
        KG14Rate: updatedAgents[agent.mobile]?.KG14Rate || agent.KG14Rate,
        KG19Rate: updatedAgents[agent.mobile]?.KG19Rate || agent.KG19Rate,
        KG5Rate: updatedAgents[agent.mobile]?.KG5Rate || agent.KG5Rate,
        fare: updatedAgents[agent.mobile]?.fare || agent.fare
      };

      const result = await axios.post(BASE_URL + "agent/update", data, {
        headers: {
          encryption: false,
          token: getToken()
        },
      });

      if (result.data && result.data.status === "success") {
        showToast("Updated successfully", "success");
      }
    } catch (error) {
      //@ts-ignore
      showToast(error.response.data.message, "error");
    }
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="md">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} style={{ marginTop: "6rem" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>View All Agents list</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Agent Name</TableCell>
                        <TableCell align="right">Mobile Number</TableCell>
                        <TableCell align="right">Active</TableCell>
                        <TableCell align="right">14KG Rate</TableCell>
                        <TableCell align="right">19KG Rate</TableCell>
                        <TableCell align="right">5KG Rate</TableCell>
                        <TableCell align="right">Fare</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.map((agent, index) => (
                        <TableRow key={agent.mobile}>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell component="th" scope="row">
                            {agent.name}
                          </TableCell>
                          <TableCell align="right">{agent.mobile}</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={<Switch checked={agent.active} onChange={() => toggleChecked(agent.mobile)} />}
                              label=""
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="14KG Rate"
                              name="KG14Rate"
                              value={updatedAgents[agent.mobile]?.KG14Rate || agent.KG14Rate}
                              onChange={(e) => handleChange(e, agent)}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="19KG Rate"
                              name="KG19Rate"
                              value={updatedAgents[agent.mobile]?.KG19Rate || agent.KG19Rate}
                              onChange={(e) => handleChange(e, agent)}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="5KG Rate"
                              name="KG5Rate"
                              value={updatedAgents[agent.mobile]?.KG5Rate || agent.KG5Rate}
                              onChange={(e) => handleChange(e, agent)}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="Fare"
                              name="fare"
                              value={updatedAgents[agent.mobile]?.fare || agent.fare}
                              onChange={(e) => handleChange(e, agent)}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              color="primary"
                              onClick={() => handleSubmit(agent)}
                            >
                              Update
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default AgentList;
