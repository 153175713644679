import React, { useState, useEffect, ChangeEvent } from 'react';
import { TextField, FormControl, MenuItem, CircularProgress, Typography } from '@material-ui/core';

interface Agent {
    label: string;
    value: string;
    fare: number;
    KG14Rate: number;
    KG19Rate: number;
    KG5Rate: number;
}

interface AgentSearchProps {
    onSelectAgent: (agent: Agent) => void;
    list: Array<Agent>
}

const AgentSearch: React.FC<AgentSearchProps> = ({ onSelectAgent, list }) => {
    const [agentList, setAgentList] = useState<Agent[]>(list);
    const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery && !selectedAgent) {
                setLoading(true);
                searchLocalAgents(searchQuery);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, selectedAgent]);

    const handleSearchChange = (event: any) => {
        setSearchQuery(event.target.value);
        setSelectedAgent(null);
    };

    const searchLocalAgents = (query: string) => {
        if (!query) {
            setAgentList(list);
            setLoading(false);
            return;
        }
        const filteredAgents = list.filter(agent =>
            agent.label?.toLowerCase().includes(query.toLowerCase())
        );
        setAgentList(filteredAgents);
        setLoading(false);
    };

    const handleAgentSelect = (agent: Agent) => {
        setSelectedAgent(agent);
        setSearchQuery(agent.label); // Set input value to the selected agent's label
        onSelectAgent(agent);
    };

    return (
        <FormControl>
            <TextField
                label="Search Agent"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <div style={{ maxHeight: 200, overflow: 'auto', marginTop: 5 }}>
                {loading ? (
                    <MenuItem disabled>
                        <CircularProgress size={20} />
                    </MenuItem>
                ) : (
                    searchQuery && !selectedAgent &&
                    agentList.map((agent: Agent) => (
                        <MenuItem
                            key={agent.value}
                            onClick={() => handleAgentSelect(agent)}
                        >
                            {agent.label}
                        </MenuItem>
                    ))
                )}
            </div>

        </FormControl>
    );
};

export default AgentSearch;
